import axios from "axios";

import { authHeader } from "./_helper";

// const rootURL = "http://localhost:2250/api/v1/";
const emailURL = "https://emails.fillform2earn.com/api/";
const rootURL = "https://server.fillform2earn.com:2250/api/v1/";

const dashboardURL = rootURL + "dashboard";
const accountURL = rootURL + "account";
const authURL = rootURL + "auth";
const companyDetailsURL = rootURL + "company-details";
const userProjectURL = rootURL + "user-project"
const userDetailsURL = rootURL + "user-details" 
const withdrawlListURL = rootURL + "withdrawl-list" 
const formFilesURL=rootURL +'form-files'
const collectionURL = rootURL + 'form-collections'
const userFormFilesURL = rootURL +'user-form-files'


 
/***** Login *****/
function verifyId(email, password) {
  return axios.post(authURL + "/root/login", { email, password });
}

function loginHistory(limit, offset) {
  return axios.get(
    rootURL + "login-history?limit=" + limit + "&offset=" + offset,
    { headers: authHeader() }
  );
}
/***** Login *****/
/*******admin********/
function getAdmin(limit,offset,keyword,status,role){
  return axios.get(
     accountURL + "/subadmin?limit=" + limit + "&offset=" + offset + "&keyword=" + keyword + "&status=" + status + "&role="+role,
    { headers: authHeader() }
  );
}
// admin
function StatusChange(id,status){
  return axios.put(accountURL +'/'+id,{status},{ headers: authHeader() })
}

function getUserProfile(id){
  return axios.get(accountURL + '/profile/' + id,
  { headers: authHeader() })
}

function profile() {
  return axios.get(companyDetailsURL + '/profile',
    { headers: authHeader() })
}

function profileImage(file){
  return axios.patch(companyDetailsURL+ "/profileImage" ,{file},{ headers: authHeader("FormData") })
}

function updateProfile(password, address, companyName, managerName, phoneNumber, website, configMail,configMailPassword,state, city, pincode, locality, country, landmark, whatsapp){
  return axios.patch(companyDetailsURL ,{password, address, companyName, managerName, phoneNumber, website, configMail,configMailPassword,state, city, pincode, locality, country, landmark, whatsapp},{ headers: authHeader() })
}

function getUser(limit,offset,keyword,status,role,projectStatus){
  return axios.get(
     accountURL + "?limit=" + limit + "&offset=" + offset + "&keyword=" + keyword + "&status=" + status + "&role="+role + '&projectStatus='+projectStatus,
    { headers: authHeader() }
  );
}

function getSubUser(limit, offset, keyword, status, role, projectStatus, accountId){
  return axios.get(
    accountURL + '/subadmin-users/' + accountId + "?limit=" + limit + "&offset=" + offset + "&keyword=" + keyword + "&status=" + status + "&role="+role + '&projectStatus='+projectStatus,
    { headers: authHeader() }
  );
}

function addUser(subAdminId,subAdmin,phoneNumber,name,email,role,startDate,duration,endDate){
  return axios.post(accountURL ,{subAdminId,subAdmin,phoneNumber,name,email,role,startDate,duration,endDate},{ headers: authHeader() })
}

function UserProfileUpdate(id,  firstName, lastName, mobile, password,delay,level2,level3){
  return axios.put(userDetailsURL+ "/profile/"+id ,{firstName, lastName, mobile, password,delay,level2,level3},{ headers: authHeader() })
}

function getwithdrawlList(limit, offset,keyword,status){
  return axios.get(
    withdrawlListURL +'/company'+ "?limit=" + limit + "&offset=" + offset + "&keyword=" + keyword + "&status=" + status,
    { headers: authHeader() }
  );
}

function setStatus(id,status){
  return axios.put(
    withdrawlListURL +'/'+id, {status},
    { headers: authHeader() }
  );
}

function addProject(title, type, level, quantity, price, startDate, endDate, userDetailId,desc){
  return axios.post(userProjectURL ,{title, type, level, quantity, price, startDate, endDate, userDetailId,desc},{ headers: authHeader() })
}

function UpdateProject(title, type, level, quantity, price, startDate, endDate, userDetailId,desc,id){
  return axios.patch(userProjectURL +'/'+id ,{title, type, level, quantity, price, startDate, endDate, userDetailId,desc},{ headers: authHeader() })
}

function getUserProject(accountId){
  return axios.get(
    userProjectURL +'/all/' +accountId,
    { headers: authHeader() }
  );
}

function updateUserProjectTime(id, startDate, duration, endDate, status){
  return axios.patch(userProjectURL + '/' +id ,{startDate, duration, endDate, status},{ headers: authHeader() })
}

function ProjectDelete(id){
  return axios.delete(userProjectURL +'/' +id,{ headers: authHeader() });
}

function getProjectDetails(id){
  return axios.get(userProjectURL + "/"+id,
    { headers: authHeader() }
  );
}

function getDashboardCounts() {
  return axios.get(
    dashboardURL,
    { headers: authHeader() }
  );
}

// for form file
function getFormFiles(limit,offset,keyword,status){
  return axios.get(
    formFilesURL + "?limit=" + limit + "&offset=" + offset + "&keyword=" + keyword+ "&status=" + status,
    { headers: authHeader() }
  );
}

function AddFormFile(name, status){
  return axios.post(
    formFilesURL ,{name, status},
    { headers: authHeader() }
  );
}

function deleteFormFile(id){
  return axios.delete(
    formFilesURL +'/'+id ,
    { headers: authHeader() }
  );
}

function setFormStatus(id,status){
  return axios.patch(
    formFilesURL +'/'+id ,{status},
    { headers: authHeader() }
  );
}

function getFormCollections(formFileId){
return axios.get(collectionURL + '/all/' +formFileId,{ headers: authHeader() })
}

function addFormCollection(serialNo, title, firstName, lastName, initial, email, fatherName, dob, gender, profession, mailingStreet, mailingCity, mailingPostalCode, mailingCountry, serviceProvider, fileNo, referenceNo, simNo, typeOfNetwork, cellModelNo, imei1, imei2, typeOfPlan, creditCardType, contractValue, dateOfIssue, dateOfRenewal, installment, amountInWords, remarks, formFileId){
  return axios.post(collectionURL,
    {
      serialNo, title, firstName, lastName, initial, email, fatherName, dob, gender, profession, mailingStreet, mailingCity, mailingPostalCode, mailingCountry, serviceProvider, fileNo, referenceNo, simNo, typeOfNetwork, cellModelNo, imei1, imei2, typeOfPlan, creditCardType, contractValue, dateOfIssue, dateOfRenewal, installment, amountInWords, remarks, formFileId
    },{ headers: authHeader() })
}

function addFormcsv(formFileId, data){
  return axios.post(collectionURL + "/insertcsv", { formFileId, data },{ headers: authHeader() })
}

function updateFormCollection(serialNo, title, firstName, lastName, initial, email, fatherName, dob, gender, profession, mailingStreet, mailingCity, mailingPostalCode, mailingCountry, serviceProvider, fileNo, referenceNo, simNo, typeOfNetwork, cellModelNo, imei1, imei2, typeOfPlan, creditCardType, contractValue, dateOfIssue, dateOfRenewal, installment, amountInWords, remarks, formFileId,id){
  return axios.patch(collectionURL + '/'+id,
    {
      serialNo, title, firstName, lastName, initial, email, fatherName, dob, gender, profession, mailingStreet, mailingCity, mailingPostalCode, mailingCountry, serviceProvider, fileNo, referenceNo, simNo, typeOfNetwork, cellModelNo, imei1, imei2, typeOfPlan, creditCardType, contractValue, dateOfIssue, dateOfRenewal, installment, amountInWords, remarks, formFileId
    },{ headers: authHeader() })
}

function getOneFormCollection(id){
  return axios.get(collectionURL + '/' +id,{ headers: authHeader() })
}

function deleteCollection(id){
  return axios.delete(collectionURL + '/' +id,{ headers: authHeader() })
}

// User form files
function AssginCollection(accountId, formFileId, status){
  return axios.post(
    userFormFilesURL ,{accountId, formFileId, status},
    { headers: authHeader() }
  );
}

function getAllUserCollection(limit,offset,keyword,status, id){
  return axios.get(
    userFormFilesURL + "/" + id + "?limit=" + limit + "&offset=" + offset + "&keyword=" + keyword+ "&status=" + status,
    { headers: authHeader() }
  );
}

function deleteUserFormFile(id){
  return axios.delete(
    userFormFilesURL +'/'+id ,
    { headers: authHeader() }
  );
}

function setUserFormStatus(id,status){
  return axios.patch(
    userFormFilesURL +'/'+id ,{status},
    { headers: authHeader() }
  );
}

// send mail
async function sendmail(receiverName, receiverEmail, password, supportno) {
  return await axios.post(emailURL + "registerMail", {receiverName, receiverEmail, password, supportno });
}

function updateMyPass(oldPassword, password, confirmPassword) {
  return axios.patch(
    accountURL + "/password",
    { oldPassword, password, confirmPassword },
    { headers: authHeader() }
  );
}

export const apiServices = {
  // admin login
  verifyId,
  loginHistory,
  // admin
  getAdmin,
  // user
  StatusChange,
  profile,
  updateProfile,
  getUser,
  getSubUser,
  addUser,
  UserProfileUpdate,
  profileImage,
  getwithdrawlList,
  setStatus,
  addProject,
  UpdateProject,
  getUserProfile,
  getUserProject,
  ProjectDelete,
  updateUserProjectTime,
  getProjectDetails,
  getDashboardCounts,
  getFormFiles,
  AddFormFile,
  setFormStatus,
  deleteFormFile,
  getFormCollections,
  addFormCollection,
  addFormcsv,
  getOneFormCollection,
  updateFormCollection,
  deleteCollection,

  AssginCollection,
  getAllUserCollection,
  setUserFormStatus,
  deleteUserFormFile,

  sendmail,
  updateMyPass,
};
